<template>
  <el-container :style="defaultHeight">
    <el-aside
      :width="isCollapse ? '3%' : '20%'"
      :style="isCollapse ? 'transition: width .5s;' : ''"
    >
      <h1
        :style="
          isCollapse
            ? 'font-size: 0px;transition: font-size .3s;'
            : 'font-size:20px;transition: font-size .3s;'
        "
      >
        考试列表
      </h1>
      <div
        :width="isCollapse ? '0px' : '100%'"
        :style="isCollapse ? 'transition: width .5s;' : ''"
        class="aside-input"
      >
        <el-input
          v-model="queryInfo.examName"
          placeholder="输入考试名称"
          prefix-icon="el-icon-search"
          v-if="!isCollapse"
          class="myinput"
          @input="getExamInfoList"
        ></el-input>
      </div>
      <!-- 关闭展开二级菜单的按钮 -->
      <i class="el-icon-menu closeBtn1" @click="collapseStatus()"></i>
      <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical-demo main-menu"
        :style="menuHeight"
        :collapse="isCollapse"
      >
        <!-- 列表区域 -->
        <el-menu-item
          :index="index.toString()"
          v-for="(item, index) in examInfoList"
          :key="index"
          @click="chooseChecked(item.uuid, item.name, index.toString())"
        >
          <span slot="title" style="font-size: 12px">{{ item.name }}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <!-- 展开二级菜单的按钮 -->
    <!-- <i
                class="el-icon-menu closeBtn2"
                v-if="isCollapse"
                @click="collapseStatus()"
        ></i> -->
    <!-- 右侧content-->
    <el-main>
      <div class="btndiv">
        <el-button
          type="primary"
          @click="showAddDialog()"
          icon="el-icon-plus"
          class="hbtn"
        >
          单个新建
        </el-button>
        <el-button
          type="primary"
          @click="showImportDialog"
          icon="el-icon-plus"
          class="hbtn"
        >
          批量导入信息
        </el-button>
        <!-- <el-button type="primary">上传身份证照片</el-button>-->
        <el-button
          type="primary"
          @click="exportExcel"
          icon="el-icon-download"
          class="hbtn"
        >
          导出全量表
        </el-button>
        <el-button
          type="danger"
          @click="deleteSelection"
          icon="el-icon-close"
          class="hbtn"
        >
          批量删除
        </el-button>
      </div>
      <el-card class="box-card" :style="cardHeight" style="overflow: auto">
        <div slot="header" class="clearfix">
          <el-row>
            <el-col :span="6">
              <span class="title">考生列表</span>
            </el-col>
            <el-input
              placeholder="请输入考生准考证号"
              prefix-icon="el-icon-search"
              v-model="zkzNum"
              clearable
              @input="getStuInfoByZkzNum"
            >
            </el-input>
            <el-input
              placeholder="请输入考生姓名"
              prefix-icon="el-icon-search"
              v-model="pageListInfo.name"
              clearable
              @input="getStuInfoListByPage"
            >
            </el-input>
          </el-row>
        </div>
        <el-table
          ref="multipleTable"
          :data="stuInfoList"
          tooltip-effect="light"
          stripe
          style="font-size: 14px"
          :max-height="tableHeight"
          @selection-change="handleSelectionChange"
        >
          <!-- :header-cell-style="{background:'#FAFAFA',}">-->
          <el-table-column
            type="selection"
            prop="id"
            label="全选"
            align="center"
            min-width="55px"
            fixed
          >
          </el-table-column>
          <el-table-column
            prop="zkzNum"
            label="准考证号"
            align="center"
            min-width="120px"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            align="center"
            min-width="100px"
          >
          </el-table-column>
          <el-table-column
            prop="schoolName"
            label="中学"
            align="center"
            min-width="120px"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="idCard"
            label="身份证号"
            align="center"
            min-width="160px"
          >
          </el-table-column>
          <el-table-column
            prop="phone"
            label="监护人手机号"
            align="center"
            min-width="120px"
          >
          </el-table-column>
          <el-table-column
            prop="jsonSubjects"
            label="科目"
            align="center"
            min-width="120px"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ getSubName(scope.row.jsonSubjects) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="answerUrl"
            label="答案缩略图"
            align="center"
            min-width="120px"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="toImgList(scope.row)"
                >查看</el-button
              >
            </template>
            <!-- <template slot-scope="scope">
              <span
                v-if="scope.row.answerUrl "
              >
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.answerUrl"
                  :preview-src-list="[scope.row.answerUrl]"
                >
                </el-image>
              </span>
              <span v-else> 暂无可预览图片 </span>
            </template> -->
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            min-width="150px"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                style="background-color: #8b2d87"
                size="mini"
                @click="showEditDialog(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                style="background-color: #da8309"
                size="mini"
                type="danger"
                @click="deleteOne(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 查看图片 -->
        <imgListDialog
          :dialogVisibleImg="dialogVisibleImg"
          :zkzNums="zkzNums"
          :names="names"
          :subjectUuids="subjectUuids"
          :exam_id="exam_id"
          @closeImgListDialog="closeImgListDialog"
        />
        <!--学生信息弹框-->
        <StuInfoDialog
          :dialogVisible="stuDialogType"
          :dialogMessage="dialogMessage"
          :stuInfo="stuInfo"
          :examId="pageListInfo.examId"
          :subjectList="subjectList"
          @closeDialog="closeDialog"
        >
        </StuInfoDialog>
        <!--批量导入弹框-->
        <ImportExcelDialog
          :dialogVisible="importDialogType"
          :excelType="excelType"
          :examId="pageListInfo.examId"
          @closeDialog="closeDialog"
        >
        </ImportExcelDialog>
        <!--            分页-->
        <div class="page">
          <el-pagination
            :background="true"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageListInfo.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageListInfo.pageSize"
            :pager-count="5"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-count="totalPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import StuInfoDialog from "c/admin/examinee/StuInfoDialog";
import imgListDialog from "c/admin/examinee/imgListDialog";
import ImportExcelDialog from "c/admin/examinee/ImportExcelDialog";
import {
  getExamData,
  getStuData,
  getStuPageData,
  studentIsGroup,
  deleteStuById,
  deleteStudents,
  getSubAllByExamId,
  getStuByZkzNum,
 
} from "r/admin/examinee";
import { export2Excel } from "common/exportExcel";
import { getDate } from "common/utils";

export default {
  name: "Lists",
  components: {
    StuInfoDialog, //新增或编辑弹框
    ImportExcelDialog, //批量导入弹框
    imgListDialog, //查看图片
  },
  data() {
    return {
      queryInfo: {
        //考试列表查询条件
        examName: "",
        pageNum: 1, //当前页
        pageSize: 9999, //每页显示条数
        status: null,
      },
      examInfoList: [], //考试列表
      isCollapse: false, // 是否水平折叠
      direction: "rtl",
      stuInfoList: [], //学生信息列表
      stuExcelList: [], //导出学生信息
      multipleSelection: [], //表格多选数据
      selectCount: 0, // 表格中选中项的数量
      ids: [], // 选中的列的id数组集合
      zkzNums: [], // 选中的列的zkzNum数组集合
      pageListInfo: {
        pageNum: 1, //当前页
        pageSize: 10, //每页显示条数
        examId: "", //考试ID
        name: "", //学生姓名查找关键字
      },
      total: 0, //总条数
      totalPage: 0, //总页数
      stuDialogType: false, //新增或编辑弹框状态
      dialogMessage: {
        //新增或编辑弹框信息
        title: "",
        btnText: "",
      },
      stuInfo: {}, //修改学生信息
      subjectList: [], //考试科目列表
      // index: 0, //考试列表选中
      importDialogType: false, //批量导入弹框状态
      excelType: "1", //导入的Excel：1（学生），2（监控人员）
      examName: "", //考试名称（导出全量表名称）
      zkzNum: "", //准考证查找学生
      defaultHeight: {
        height: "",
      },
      menuHeight: {
        height: "",
      },
      cardHeight: {
        height: "",
      },
      tableHeight: "",
      activeMenu: "", // 当前激活的二级分类
      // 查看上传图片
      dialogVisibleImg: false,
      zkzNums: "", //
      names: "", //
      subjectUuids: "", //
      exam_id: "", //
    };
  },
  created() {
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
    this.activeMenu = window.sessionStorage.getItem("admin-activeMenu");
    this.getExamInfoList();
    // this.getStuInfoList()
    // this.getStuInfoListByPage()
  },
  methods: {
    // 查看缩略图 
    toImgList(row) {
      console.log(row, "row");
      this.dialogVisibleImg = true;
      this.zkzNums = row.zkzNum;
      this.names = row.name;
      this.subjectUuids = row.subjectUuid;
      this.exam_id = row.examId;
    },
    closeImgListDialog(val, type) {
      this.dialogVisibleImg = val;
      if (type) {
        clearInterval(this.timer);
      }
    },
    // 保存当前激活的分类
    activeHandle(active) {
      window.sessionStorage.setItem("admin-activeMenu", active);
      this.activeMenu = active;
    },

    // 替换考试科目名称
    getSubName(val) {
      if (!val) {
        return "";
      }
      let arr = this.subjectList.filter((value) => {
        if (val.indexOf(value.subjectId) > -1) {
          return value.subjectName;
        }
      });
      let list = "";
      for (let i = 0; i < arr.length; i++) {
        list += (list ? "、" : "") + arr[i].subjectName;
      }
      return list;
    },
    // 关闭弹框
    closeDialog(val) {
      this.stuDialogType = val;
      this.importDialogType = val;
      // 刷新表格
      this.getStuInfoByZkzNum();
    },
    //获取考试列表数据
    async getExamInfoList() {
      const { data: res } = await getExamData(this.queryInfo);
      if (res.code !== 200) return this.$message.error(res.message);
      this.examInfoList = res.data.records;
      // console.log(res.data.records, "获取考试列表数据");
      if (this.examInfoList.length != 0) {
        this.pageListInfo.examId =
          this.examInfoList[this.activeMenu].uuid || this.examInfoList[0].uuid;
        this.examName =
          this.examInfoList[this.activeMenu].name || this.examInfoList[0].name;
      }
      //获取考试科目列表
      await this.getSubjectList();
      //获取考生列表
      await this.getStuInfoListByPage();
    },
    // 选择二级菜单，更新表格
    chooseChecked(examId, examName, index) {
      this.pageListInfo.examId = examId;
      this.examName = examName;

      this.activeHandle(index);

      //获取考试科目列表
      this.getSubjectList();
      //获取考生列表
      this.getStuInfoListByPage();
    },
    // 获取考生列表数据(导出Excel表的数据)
    async getStuInfoList() {
      const { data: res } = await getStuData({
        examId: this.pageListInfo.examId,
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.stuExcelList = res.data;
    },
    //记录表格多选框选中数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.selectCount = val.length;
      this.ids = [];
      this.zkzNums = [];
      val.forEach((item) => {
        // console.log(item);
        this.ids.push(item.id);
        this.zkzNums.push(item.zkzNum);
      });
    },
    //控制一页显示多少条
    handleSizeChange(val) {
      this.pageListInfo.pageSize = val;
      this.getStuInfoByZkzNum();
    },
    //控制显示第几页
    handleCurrentChange(val) {
      this.pageListInfo.pageNum = val;
      this.getStuInfoByZkzNum();
    },
    //分页获取考生列表数据(包括按姓名搜索学生)
    async getStuInfoListByPage() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await getStuPageData(this.pageListInfo);

      loading.close();
      if (res.code !== 200) return this.$message.error(res.message);

      this.stuInfoList = res.data.records;
      // console.log(this.stuInfoList, "this.stuInfoList ");
      this.total = res.data.total;
      this.totalPage = res.data.pages;
    },
    //按准考证号搜索学生
    async getStuInfoByZkzNum() {
      if (this.zkzNum != "") {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0,0,0,0.7)",
        });
        const { data: res } = await getStuByZkzNum({
          zkzNum: this.zkzNum,
          pageNum: this.pageListInfo.pageNum,
          pageSize: this.pageListInfo.pageSize,
          examId: this.pageListInfo.examId,
        });
        loading.close();
        if (res.code !== 200) return this.$message.error(res.message);
        this.stuInfoList = res.data.records;
        this.total = res.data.total;
        this.totalPage = res.data.pages;
      } else {
        await this.getStuInfoListByPage();
      }
    },
    //获取考试科目数据
    async getSubjectList() {
      const { data: res } = await getSubAllByExamId(this.pageListInfo.examId);
      if (res.code !== 200) return this.$message.error(res.message);
      this.subjectList = res.data;
    },
    // 打开单个新增弹框
    showAddDialog() {
      this.stuDialogType = true;
      this.dialogMessage.title = "单个新建";
      this.dialogMessage.btnText = "创 建";
      this.stuInfo = {
        createTime: new Date(),
        examId: this.pageListInfo.examId,
        headImg: "",
        idCard: "",
        jsonSubjects: [],
        name: "",
        password: "",
        phone: "",
        schoolId: 0,
        schoolName: "",
        zkzNum: "",
      };
    },
    // 打开编辑页面
    showEditDialog(row) {
      this.stuInfo = row;
      if (this.stuInfo.jsonSubjects != null) {
        this.stuInfo.jsonSubjects = this.stuInfo.jsonSubjects.split(",");
      } else {
        this.stuInfo.jsonSubjects = [];
      }
      this.stuDialogType = true;
      this.dialogMessage.title = "修改信息";
      this.dialogMessage.btnText = "修 改";
    },
    // 删除
    async deleteOne(row) {
      // 验证考生是否在监控组
      const { data: ress } = await studentIsGroup({
        examId: row.examId,
        zkzNums: row.zkzNum,
      });
      if (ress.code !== 200) return this.$message.error(ress.message);
      // const confirmResult = await this.$confirm(`确认删除考生：${row.name} ？`, "提示", {
      const confirmResult = await this.$confirm(`${ress.data}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).catch((err) => err);
      // 取消删除操作
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      //确认删除
      const { data: res } = await deleteStuById({
        id: row.id,
        examId: this.pageListInfo.examId,
      });
      if (res.code !== 200) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      await this.getStuInfoListByPage();
    },
    // 批量删除
    async deleteSelection() {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);

      if (this.selectCount <= 0)
        return this.$message.info("您还未选择需要删除的考生");

      // 验证考生是否在监控
      const { data: ress } = await studentIsGroup({
        examId: this.pageListInfo.examId,
        zkzNums: this.zkzNums.join(","),
      });
      // console.log(ress);
      if (ress.code !== 200) return this.$message.error(ress.message);
      // const confirmResult = await this.$confirm( `确认删除所选 ${this.multipleSelection.length} 条数据？`, "提示",
      const confirmResult = await this.$confirm(`${ress.data}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      }).catch((err) => err);
      // 取消删除操作
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      //确认删除
      const { data: res } = await deleteStudents({
        ids: this.ids.join(","),
        examId: this.pageListInfo.examId,
      });
      if (res.code !== 200) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      await this.getStuInfoListByPage();
    },
    // 导出学生信息
    exportExcel() {
      this.getStuInfoList().then((value) => {
        const tHeader = [
          "准考证号",
          "姓名",
          "中学",
          "身份证号",
          "监护人手机号",
          "科目",
        ]; //对应表格输出的title
        const filterVal = [
          "zkzNum",
          "name",
          "schoolName",
          "idCard",
          "phone",
          "jsonSubjects",
        ]; // 对应表格输出的数据
        let list = [];
        let date = getDate("yyyy-MM-dd_hh:mm:ss", new Date());
        // console.log(date);
        let excelName = this.examName + "_考生信息全量表" + date;
        // if(this.multipleSelection.length >0 ){
        //     list = this.multipleSelection
        //     excelName += "_部分考生信息表"
        // }else{
        //     list = this.stuExcelList
        //     excelName += "_考生信息全量表"
        // }
        if (this.multipleSelection.length > 0) {
          list = this.multipleSelection;
        } else {
          list = this.stuExcelList;
        }
        if (list.length === 0) {
          return this.$message.info("暂无学生信息");
        }
        // 调用导出excel表格公共方法
        export2Excel(tHeader, filterVal, list, excelName);
      });
    },
    //打开批量导入弹框
    showImportDialog() {
      this.importDialogType = true;
    },
    // 控制是否水平折叠收起菜单
    collapseStatus() {
      this.isCollapse = !this.isCollapse;
    },
    //定义方法，获取高度减去头尾
    getHeight() {
      this.defaultHeight.height = window.innerHeight - 93 + "px";
      this.menuHeight.height = window.innerHeight - 213 + "px";
      this.cardHeight.height = window.innerHeight - 164 + "px";
      this.tableHeight = window.innerHeight - 335 + "px";
    },
  },
};
</script>

<style scoped
       lang="scss">
@import "a/scss/menuitem";
@import "a/scss/contentheader";
@import "a/scss/table";
@import "a/scss/page";

// i.el-message-box__close.el-icon-close:hover {
//   color: #5c307d;
// }
// .el-button.el-button--default.el-button--small.el-button--primary {
//   background-color: #5c307d;
//   border-color: #5c307d;
// }
// .el-button.el-button--default.el-button--small.el-button--primary:hover {
//   background-color: #5c307d;
//   border-color: #5c307d;
//   color: white;
// }
// .el-button.el-button--default.el-button--small:hover {
//   background-color: #f8effb;
//   border-color: #5c307d;
//   color: #5c307d;
// }

// .el-select-dropdown__item.selected {
//   color: #8b2d87;
// }
</style>
