<template>
  <!-- dialog弹框 -->
  <el-dialog
    :visible.sync="dialogVisible"
    title="查看上传图片"
    center
    width="750px"
    @open="openDialog"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
<!--    <div class="imgList">-->
<!--      <div class="img_title">{{ name }}-{{ zkzNum }}</div>-->
<!--      <div v-for="(item, index) in imgUrl" :key="index" class="Img_subject">-->
<!--        <span>{{ item.subjectName }}</span>-->
<!--        <div v-if="Object.keys(item.answer).length > 0">-->
<!--          <div class="ever_List">-->
<!--            <div-->
<!--              v-for="(item2, index2) in item.answer"-->
<!--              :key="index2"-->
<!--              class="flex each_subject"-->
<!--            >-->
<!--              <span class="pic_sub">{{ item2.name }}</span>-->
<!--              <div-->
<!--                v-for="(item3, key) in item2.value"-->
<!--                :key="item3[key]"-->
<!--                class="flex imgList"-->
<!--              >-->
<!--                <span class="pic_id"> 第 {{ key }} 题</span>-->
<!--                <el-image-->
<!--                  style="width: 100px; height: 100px"-->
<!--                  :src="item3"-->
<!--                  :preview-src-list="[item2.value[key]]"-->
<!--                  lazy-->
<!--                >-->
<!--                </el-image>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="block" v-else>暂未上传图片</div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="imgList">
      <div v-for="(item, index) in imgUrl" :key="index" class="Img_subject">
        <span>{{ item.name }}</span>
        <div v-if="Object.keys(item.value).length > 0">
          <div class="ever_List">
              <div
                  v-for="(item3, key) in item.value"
                  :key="key"
                  class="flex imgList"
              >
                <span class="pic_id"> 第 {{ key }} 题</span>
                <el-image
                    style="width: 100px; height: 100px"
                    :src="item3"
                    :preview-src-list="[item.value[key]]"
                    lazy
                >
                </el-image>
              </div>
          </div>
        </div>
        <div class="block" v-else>暂未上传图片</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getAdminStudentUrl } from "r/admin/examinee";
export default {
  name: "imgListDialog",
  props: {
    dialogVisibleImg: Boolean,
    zkzNums: String,
    names: String,
    exam_id: String,
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleImg, // 控制dialog弹框显示与隐藏
      zkzNum: this.zkzNums,
      name: this.names,
      subjectUuid: this.subjectUuids,
      examId: this.exam_id,
      imgUrl: [],
    };
  },
  methods: {
    openDialog() {
      // console.log(this.examId)
      getAdminStudentUrl({
        zkzNum: this.zkzNum,
        examId: this.exam_id,
        // subjectUuid: this.subjectUuid,
      }).then((res) => {
        console.log(res, "getAdminStudentUrl");
        if (res.data.code != 200) {
          return this.$message.error(res.data.message);
        }
        // if (res.data.data !== {}) {
        //   let arr = JSON.parse(res.data.data);
        //   console.log(arr, "arr");
        //   // return
        //   arr.map((item) => {
        //     return (item.answer = JSON.parse(item.answer));
        //   });
        //
        //   this.imgUrl = arr;
        //
        //   // console.log(this.imgUrl, "this.imgUrl");
        // }
        // todo 2022-04-01修改
        if (res.data.data !== {}) {
          let arr = JSON.parse(res.data.data);
          console.log(arr, "arr");
          // return
          // arr.map((item) => {
          //   return (item.answer = JSON.parse(item.answer));
          // });
          this.imgUrl = arr;
          console.log(this.imgUrl, "this.imgUrl");
        }
      });
    },
    cancelBtn() {
      /*  */
      this.dialogVisible = false;
      this.imgUrl = [];
      this.$emit("closeImgListDialog", this.dialogVisible, false);
    },
  },
  watch: {
    dialogVisibleImg() {
      this.dialogVisible = this.dialogVisibleImg;
    },
    zkzNums() {
      this.zkzNum = this.zkzNums;
    },
    names() {
      this.name = this.names;
    },
    subjectUuids() {
      this.subjectUuid = this.subjectUuids;
    },
    exam_id() {
      this.examId = this.exam_id;
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}
.imgList {
  .img_title {
    margin: 10px;
    font-size: 18px;
  }
  .Img_subject {
    padding: 10px 0;
    span {
      font-size: 16px;
      margin: 10px;
    }
    .ever_List {
      display: flex;
      //flex-direction: column;
      // flex-wrap: wrap;
      width: 100%;
      padding: 10px 0;
      .pic_sub {
        font-size: 13px;
        font-weight: 600;
      }
      .each_subject {
        // flex-direction: column;
        // width: 25%;
        max-width: 100%;
        min-width: 25%;
        padding: 10px 0;
      }
      .imgList {
        //flex-direction: column;
        flex-grow: 0;
        margin-right: 20px;
        .pic_id {
          font-size: 13px;
        }
      }
    }
    img {
      margin: 10px;
      width: 100px;
    }
  }
  .block {
    margin-left: 10px;
  }
}
</style>