<template>
  <el-dialog
    :title="dialogMessage.title"
    v-if="dialogVisibleForm"
    :visible.sync="dialogVisibleForm"
    center
    :before-close="handleClose"
    :close-on-click-modal="false"
    @close="resetForm"
  >
    <el-form :model="stuInfoForm" :rules="rules" ref="stuInfoForm">
      <el-form-item
        label="准考证号"
        :label-width="formLabelWidth"
        prop="zkzNum"
      >
        <el-input
          v-model="stuInfoForm.zkzNum"
          placeholder="请输入准考证号"
        ></el-input>
      </el-form-item>
      <el-form-item label="考生姓名" :label-width="formLabelWidth" prop="name">
        <el-input
          v-model="stuInfoForm.name"
          placeholder="请输入考生姓名"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="所在中学"
        :label-width="formLabelWidth"
        prop="schoolName"
      >
        <!--                <el-select v-model="querySchoolInfo.proId" placeholder="请选择省份">-->
        <!--                    <el-option-->
        <!--                            v-for="item in provinceList"-->
        <!--                            :key="item.provinceId"-->
        <!--                            :label="item.provinceName"-->
        <!--                            :value="item.provinceId">-->
        <!--                    </el-option>-->
        <!--                </el-select>-->
        <!--                <el-select v-model="querySchoolInfo.cityId" placeholder="请选择城市">-->
        <!--                    <el-option-->
        <!--                            v-for="item in cityList"-->
        <!--                            :key="item.cityId"-->
        <!--                            :label="item.cityName"-->
        <!--                            :value="item.cityId">-->
        <!--                    </el-option>-->
        <!--&lt;!&ndash;                </el-select>&ndash;&gt;-->
        <!--                <el-select v-model="stuInfoForm.schoolName" placeholder="请选择所在中学">-->
        <!--                    <el-option-->
        <!--                            v-for="item in schoolList"-->
        <!--                            :key="item.schoolId"-->
        <!--                            :label="item.schoolName"-->
        <!--                            :value="item.schoolId">-->
        <!--                    </el-option>-->
        <!--                </el-select>-->
        <div class="block">
          <el-cascader
            ref="schoolCascader"
            :props="schoolInfoList"
            :show-all-levels="false"
            :placeholder="placeholder"
            :clearable="true"
            v-model="schoolInfo"
            @change="handleChange"
            :class="stuInfo.schoolName !== '' ? 'plaColor' : ''"
          >
          </el-cascader>
        </div>
      </el-form-item>

      <el-form-item
        label="身份证号"
        :label-width="formLabelWidth"
        prop="idCard"
      >
        <el-input
          v-model="stuInfoForm.idCard"
          placeholder="请输入身份证号"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="监护人手机"
        :label-width="formLabelWidth"
        prop="phone"
      >
        <el-input
          v-model="stuInfoForm.phone"
          placeholder="请输入考生监护人手机号码"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="考试科目"
        :label-width="formLabelWidth"
        prop="jsonSubjects"
      >
        <el-checkbox-group v-model="stuInfoForm.jsonSubjects">
          <el-checkbox
            v-for="(sub, index) in subjectListForm"
            :label="sub.subjectId.toString()"
            :key="index"
            name="jsonSubjects"
            >{{ sub.subjectName }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose()">取 消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="submitForm('stuInfoForm')"
        :disabled="isDisable"
        >{{ dialogMessage.btnText }}</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import {
  createStu,
  getAllProvince,
  getCityByPro,
  getSchoolList,
  updateStuById,
} from "r/admin/examinee";
export default {
  name: "StuInfoDialog",
  props: {
    dialogVisible: {
      //弹框状态
      type: Boolean,
    },
    dialogMessage: {
      //新增or编辑状态
      type: Object,
    },
    stuInfo: {
      //学生信息
      type: Object,
    },
    examId: {
      //考试ID
      type: String,
    },
    subjectList: {
      //科目列表
      type: Array,
    },
  },
  data() {
    return {
      stuInfoForm: this.stuInfo, //学生信息
      dialogVisibleForm: this.dialogVisible, //弹框状态
      examIdForm: this.examId, //考试ID
      formLabelWidth: "100px",
      subjectListForm: this.subjectList, //科目列表
      provinceList: [], //省
      cityList: [], //市
      schoolList: [], //学校
      querySchoolInfo: {
        proId: 0,
        cityId: 0,
      },
      placeholder: "请选择所在中学", //学校下拉框默认显示
      isDisable: false, //提交按钮是否可点击（防重复点击）
      schoolInfo: [], //所选学校下拉框数组
      //学校下拉框数据
      schoolInfoList: {
        lazy: true,
        emitPath: true,
        provinceId: 0,
        schoolName: "",
        lazyLoad(node, resolve) {
          const level = node.level;
          // let result
          setTimeout(() => {
            if (level === 0) {
              getAllProvince()
                .then((response) => {
                  //省
                  const provinces = response.data.data.map((value, i) => ({
                    value: value.provinceId,
                    label: value.provinceName,
                    leaf: node.level >= 2,
                  }));
                  resolve(provinces);
                })
                .catch((err) => {});
            }
            if (level === 1) {
              this.provinceId = node.value;
              getCityByPro(node.value)
                .then((response) => {
                  //市
                  const cities = response.data.data.map((value, i) => ({
                    value: value.cityId,
                    label: value.cityName,
                    leaf: node.level >= 2,
                  }));
                  resolve(cities);
                })
                .catch((err) => {});
            }
            if (level === 2) {
              let cityId = node.value;
              let provinceId = this.provinceId;
              let info = { provinceId, cityId };
              getSchoolList(info)
                .then((response) => {
                  const schools = response.data.data.map((value, i) => ({
                    //学校
                    value: value.id,
                    label: value.name,
                    leaf: node.level >= 2,
                  }));
                  resolve(schools);
                })
                .catch((err) => {});
            }
            // resolve(result)
          }, 100);
        },
      },
      //表单提交规则
      rules: {
        zkzNum: [
          { required: true, message: "请输入准考证号", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入学生姓名", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        schoolName: [
          { required: true, message: "请选择所在中学", trigger: "change" },
        ],
        idCard: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
              pattern: /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
              message: "请输入正确的身份证号", trigger: 'blur'
          }
        ],
        phone: [
          { required: true, message: "请输入监护人手机", trigger: "blur" },
          {
              pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
              message: "请输入正确的手机号码", trigger: 'blur'
          }
        ],
        jsonSubjects: [
          {
            required: true,
            message: "请至少选择一个选择考试科目",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    // 重置表单
    resetForm() {
      this.$refs.stuInfoForm.resetFields();
    },
    // 右上角X,取消关闭弹框
    handleClose() {
      this.dialogVisibleForm = false;
      this.resetForm();
      this.schoolInfo = [];
      this.$emit("closeDialog", this.dialogVisibleForm);
    },
    // 提交表单
    submitForm(formName) {
      this.isDisable = true;
      if (this.dialogMessage.btnText == "创 建") {
        //新增表单提交
        this.$refs[formName].validate(async (valid) => {
          this.isDisable = true;
          setTimeout(() => {
            this.isDisable = false;
          }, 1000);
          if (valid) {
            this.stuInfoForm.jsonSubjects = this.stuInfoForm.jsonSubjects.join(
              ","
            );
            const { data: res } = await createStu(this.stuInfoForm);
            if (res.code !== 200) {
              this.stuInfo.jsonSubjects = [];
              return this.$message.error(res.message);
            }
            this.$message.success(res.message);
            this.handleClose();
          } else {
            return false;
          }
        });
      } else if (this.dialogMessage.btnText == "修 改") {
        //修改表单提交
        this.isDisable = true;
        setTimeout(() => {
          this.isDisable = false;
        }, 1000);
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.stuInfoForm.jsonSubjects = this.stuInfoForm.jsonSubjects.join(
              ","
            );
            const { data: res } = await updateStuById(this.stuInfoForm);
            if (res.code !== 200) return this.$message.error(res.message);
            this.$message.success(res.message);
            this.handleClose();
          } else {
            return false;
          }
        });
      }
    },
    // //获取省
    // async getProvinceList() {
    //     const {data: res} = await getAllProvince();
    //     if (res.code !== 200) return this.$message.error(res.message);
    //     this.provinceList = res.data
    //     console.log(this.provinceList)
    // },
    // //获取市
    // async getCityByPro(proId) {
    //     const {data: res} = await getCityByPro(proId);
    //     if (res.code !== 200) return this.$message.error(res.message);
    //     this.cityList = res.data
    //     console.log(this.cityList)
    // },
    // //获取学校
    // async getSchoolList() {
    //     const {data: res} = await getSchoolList(this.querySchoolInfo);
    //     if (res.code !== 200) return this.$message.error(res.message);
    //     this.schoolList = res.data
    //     console.log(this.schoolList)
    // },
    handleChange(value) {
      this.stuInfoForm.schoolId = value[2];
      this.stuInfoForm.schoolName = this.$refs.schoolCascader.getCheckedNodes()[0].pathLabels[2];
    },
  },
  watch: {
    dialogVisible() {
      this.dialogVisibleForm = this.dialogVisible;
    },
    subjectList() {
      this.subjectListForm = this.subjectList;
    },
    stuInfo() {
      this.stuInfoForm = this.stuInfo;
      if (this.stuInfo.schoolName !== "") this.placeholder = this.stuInfo.schoolName;
      else this.placeholder = "请选择所在中学";
    },
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/dialog";

::v-deep .plaColor {
  input::placeholder {
    color: #666666;
  }
}
  
</style>
